import React from "react";
import "./offer.scss";
import UploadFile from "../uploadFile";
import { Button, Container } from "react-bootstrap";
import { curved_design, right_arrow } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

const OffersSection = () => {
  const navigate = useNavigate();
  return (
    <section className="offers-section-container">
      <Container>
        <div className="offers-section">
          <div className="">
            <h1 className="text-center">What We Offer</h1>
            <p className="mt-4 offers-section-header-text">
              Our platform is designed to make sending and managing digital
              invitations easy and stress-free. Here are some of the features we
              offer:
            </p>
          </div>
          <div className="offers-section-content mt-5">
            <div className="offers-section-content-item">
              <div className="offers-section-content-item-image">
                <img
                  src={
                    "https://static.wixstatic.com/media/c837a6_07cdd24cb9e84acdb41d8c3568867354~mv2.jpg/v1/fill/w_223,h_297,al_c,q_80,usm_0.66_1.00_0.01,enc_avif,quality_auto/Artboard%2048%20copy%204_5x-100.jpg"
                  }
                  alt="offer"
                />
              </div>
              <div className="offers-section-content-item-text mt-5">
                <h3 className="fs-22 mb-3 offers-section-content-item-text-title">
                  Customizable Invitations
                </h3>
                <p>
                  Tailor your invitations to match your event's theme and style.
                  Choose from a variety of templates and customize every detail.
                </p>
              </div>
            </div>
            <div className="offers-section-content-item">
              <div className="offers-section-content-item-image">
                <img
                  src={
                    "https://static.wixstatic.com/media/c837a6_ef6bbe6808334282ab6c74203e782f28~mv2.jpg/v1/fill/w_222,h_294,al_c,q_80,usm_0.66_1.00_0.01,enc_avif,quality_auto/Artboard%2048%20copy%207_5x-100.jpg"
                  }
                  alt="offer"
                />
              </div>
              <div className="offers-section-content-item-text mt-5">
                <h3 className="fs-22 mb-3">Detailed Report</h3>
                <p>
                  Get detailed reports on your event's RSVPs and guest responses
                  in real-time. Track meal preferences and analyze attendance
                  patterns for your event.
                </p>
              </div>
            </div>
            <div className="offers-section-content-item">
              <div className="offers-section-content-item-image">
                <img
                  src={
                    "https://static.wixstatic.com/media/c837a6_070e2f97109e4b4ba2e079e8f7f18f4f~mv2.jpg/v1/fill/w_222,h_294,al_c,q_80,usm_0.66_1.00_0.01,enc_avif,quality_auto/Artboard%2048%20copy%205_5x-100.jpg"
                  }
                  alt="offer"
                />
              </div>
              <div className="offers-section-content-item-text mt-5">
                <h3 className="fs-22 mb-3">Integrated Messaging</h3>
                <p>
                  Send messages directly to your guests. Keep everyone updated
                  with real-time notifications and reminders. Our messaging
                  system makes communication easy.
                </p>
              </div>
            </div>
            <div className="offers-section-content-item">
              <div className="offers-section-content-item-image">
                <img
                  src={
                    "https://static.wixstatic.com/media/c837a6_1f658b7b98b846d4865a783d52c8b43c~mv2.jpg/v1/fill/w_222,h_294,al_c,q_80,usm_0.66_1.00_0.01,enc_avif,quality_auto/Artboard%2048%20copy%206_5x-100.jpg"
                  }
                  alt="offer"
                />
              </div>
              <div className="offers-section-content-item-text mt-5">
                <h3 className="fs-22 mb-3">Hassel Free Invitation</h3>
                <p>
                  Our platform simplifies the invitation process, allowing you
                  to focus on what truly matters - your event.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default OffersSection;
