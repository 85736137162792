import React from "react";
import "./occasion.scss";
import UploadFile from "../uploadFile";
import { Button, Container } from "react-bootstrap";
import { curved_design, right_arrow } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

const OccasionSection = () => {
  const navigate = useNavigate();
  return (
    <section className="occasion-section-container">
      <Container>
        <div className="occasion-section d-flex align-items-center justify-content-between">
          <div className="occasion-section-left">
            <h1>Make Every Occasion Special</h1>
          </div>
          <div className="occasion-section-right">
            <div>
              <p>
                At ZeroPaper, we believe that every event deserves a special
                invitation. That's why we've created a platform that allows you
                to customize every detail of your digital invitation. From the
                colors to the fonts, you have complete control over the design.
              </p>
            </div>
            <div>
              <b>Instant WhatsApp Dispatch</b>
              <p>
                Embrace efficiency! Share your invitations directly to your
                guests' WhatsApp with a single tap. Guarantee your invites are
                delivered promptly and hassle-free.
              </p>
            </div>
            <div>
              <b>Complete Design Freedom</b>
              <p>
                Unleash your creativity! With ZeroPaper Invites, you have total
                control over every detail. Select fonts, colors, layouts, and
                backgrounds to perfectly match your style and event theme.
              </p>
            </div>
            <div>
              <b>User-Friendly Interface</b>
              <p>
                No tech-savvy skills needed! Our user-friendly platform guides
                you through the process, making invitation creation a breeze,
                regardless of your familiarity with technology.
              </p>
            </div>
            <div>
              <b>Flawless Cross-Device Experience</b>
              <p>
                Your invites will shine on any device – be it a smartphone,
                tablet, or computer. All your guests will enjoy a seamless
                experience, no matter their chosen device.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default OccasionSection;
