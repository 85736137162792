import React from "react";
import "./invitationBio.scss";
import UploadFile from "../uploadFile";
import { Button, Container } from "react-bootstrap";
import { curved_design, right_arrow } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/images/bg.png";

const InvitationBioSection = () => {
  const navigate = useNavigate();
  return (
    <section className="invitation-bio-section-container">
      <Container>
        <div className="invitation-bio-section">
          <div className="w-75 text-center mx-auto invitation-bio-section-header-text-container">
            <h2 className="invitation-bio-section-header-text">
              Ready to make your next event absolutely unforgettable with
              ZeroPaper! Log in now and dive into creating your amazing
              customized invitations!
            </h2>
          </div>

          <div className="text-center mt-4">
            <Button
              variant="primary"
              className="fs-18 px-4 py-1 login-button"
              onClick={() => {
                navigate("/send-invitation");
              }}
            >
              Get Started
            </Button>
          </div>
        </div>
      </Container>
      <div className="bg-design-bottom">
        <img src={bg} alt="curved design" />
      </div>
    </section>
  );
};

export default InvitationBioSection;
