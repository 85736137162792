import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GuestData from "../../guestData";
import { setExcelData } from "../../../redux-slice/filesSlice";
import "./index.scss";
import { Button } from "react-bootstrap";
import { left_arrow } from "../../../assets/icons";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  convertExcelToJson,
  copyTextToClipboard,
  exportExcelFile,
} from "../../../utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap/esm";
import QRCode from "qrcode.react";
import whatsapp_icon from "../../../assets/logo/whatsapp-logo.png";
import { WaitingMessage } from "../../customer/waitingMessage";
import { socket } from "../../../config/socket.config";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { updateUsers } from "../../../api/api";
import { toast } from "react-hot-toast";
import { setInvitationWithUsers } from "../../../redux-slice/statusSlice";
import SuccessMessage from "../../successMessage";

const Status = (props) => {
  const {
    invitationWithUsers,
    qrCode,
    user,
    currentRow,
    newExcelData,
    screenshot = "",
  } = props;
  const _inputProps = {
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template": [
      ".xltx",
    ],
    "application/vnd.ms-excel-template": [".xlt"],
    "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
    "application/vnd.ms-excel.template.macroEnabled.12": [".xltm"],
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],
    "text/csv": [".csv"],
    "text/tab-separated-values": [".tab"],
  };

  const params = useParams();
  const invitationId = params?.invitationId;

  const { excelData } = useSelector((state) => state.files);
  const {
    isShowImportExportButtons,
    isShowWaitingMessage,
    isShowSuccessMessage,
  } = useSelector((state) => state.status);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const { guestData } = await convertExcelToJson(acceptedFiles[0]);
    await updateUsersViaExcel(guestData);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: _inputProps,
  });

  const handleBeforeUnload = () => {
    socket.emit("logout", invitationId);
  };

  const handlePopstate = () => {
    handleBeforeUnload();
  };

  window.addEventListener("beforeunload", handleBeforeUnload);
  window.removeEventListener("popstate", handlePopstate);

  const initializeSocket = (socket) => {
    socket.emit("joinRoom", invitationId);
  };

  const joinRoom = () => {
    socket.emit("joinRoom", invitationId);
  };

  const leaveRoom = () => {
    socket.emit("leaveRoom", invitationId);
  };

  const createExcelHeaders = (users) => {
    if (users?.length > 0) {
      const headers = Object.keys(users[0]);
      return headers;
    }
  };

  const filterExcelData = (users) => {
    if (users?.length > 0) {
      const filteredData =
        users?.length > 0 &&
        users.map((user, i) => {
          return {
            _id: user._id,
            ...user.details,
            status: user.status,
          };
        });
      return filteredData;
    }
  };

  const updateExcelData = (invitationWithUsers) => {
    const guestData = filterExcelData(invitationWithUsers.users);
    const excelHeaders = createExcelHeaders(guestData);
    const excelData = {
      guestData,
      excelHeaders,
    };
    dispatch(setExcelData(excelData));
  };

  const updateUsersViaExcel = async (users) => {
    let clonedExcelData = JSON.parse(JSON.stringify(excelData));
    let clonedGuestData = clonedExcelData?.guestData;
    const usersStatus = await updateUsers(users);
    if (usersStatus?.length > 0) {
      const updatedUsers = usersStatus.filter((user) => {
        const updateStatus = user?.value;
        return (
          user?.status === "fulfilled" &&
          updateStatus?.isUpdated &&
          updateStatus
        );
      });

      let notUpdatedUsers = usersStatus.filter((user) => {
        const updateStatus = user?.value;
        return user?.status === "rejected" && updateStatus;
      });

      if (updatedUsers.length > 0) {
        for (let i = 0; i < updatedUsers.length; i++) {
          const user = updatedUsers[i].value;
          const _id = user._id;
          const index = clonedGuestData.findIndex((guest) => guest._id === _id);
          let guest = clonedGuestData[index];
          guest = {
            ...guest,
            ...user?.updatedUser,
            status: "pending",
          };
          delete guest.isUpdating;
          clonedGuestData = [
            ...clonedGuestData.slice(0, index),
            guest,
            ...clonedGuestData.slice(index + 1),
          ];
        }
        updateInvitationWithUsers(clonedGuestData);
        clonedExcelData.guestData = clonedGuestData;
        dispatch(setExcelData(clonedExcelData));
        toast.success("Guest details updated!");
      }
      notUpdatedUsers.length > 0 && toast.error("Try again after someTime");
    }
  };

  const updateInvitationWithUsers = (guests) => {
    const clonedGuestData = JSON.parse(JSON.stringify(guests));
    const clonedInvitationWithUsers = JSON.parse(
      JSON.stringify(invitationWithUsers)
    );

    const updatedUsers = clonedInvitationWithUsers?.users?.map((user, i) => {
      const guest_ = clonedGuestData.find((guest) => guest?._id === user?._id);
      const status = guest_?.status;
      delete guest_?._id;
      delete guest_?.status;
      delete guest_?.isUpdating;
      return {
        ...user,
        details: guest_,
        status: status,
      };
    });
    clonedInvitationWithUsers.users = updatedUsers;
    dispatch(setInvitationWithUsers(clonedInvitationWithUsers));
  };

  useEffect(() => {
    if (invitationId) initializeSocket(socket);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
      // socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (invitationWithUsers) {
      updateExcelData(invitationWithUsers);
    }
  }, [invitationWithUsers]);

  return (
    <>
      {isShowWaitingMessage ? (
        <div className="p-20 w-100 d-flex justify-content-center align-items-center h-100">
          <WaitingMessage />
        </div>
      ) : isShowSuccessMessage ? (
        <div className="p-20 w-100 d-flex justify-content-center align-items-center h-100">
          <SuccessMessage id={invitationId} />
        </div>
      ) : (
        excelData && (
          <div className="p-20 mx-auto w-fit-content">
            <div className="d-flex justify-content-between">
              <Button
                variant="light"
                className="back-button d-flex align-items-center fs-22 justify-content-evenly mb-2"
                onClick={() => {
                  navigate("/variable", { replace: true });
                  handleBeforeUnload();
                }}
              >
                {left_arrow()} Back
              </Button>
            </div>
            <div className="mb-2 bg-white py-1 px-3 d-flex align-items-center justify-content-around rounded-3">
              <div className="d-flex">
                <p className="mb-0">
                  <span className="fw-500">Invitation Id :</span>
                  {invitationId}
                </p>
                <OverlayTrigger
                  placement="top"
                  className=""
                  overlay={<Tooltip id="button-tooltip-2">Copy</Tooltip>}
                >
                  <p
                    className="ms-2 mb-0 cursor-pointer"
                    onClick={() => {
                      copyTextToClipboard(invitationId);
                    }}
                  >
                    &#128203;
                  </p>
                </OverlayTrigger>
              </div>
              {/* <div style={{ height: "auto", width: "100%" }}>
                <img
                  src={screenshot}
                  alt=""
                  style={{ width: "100%", height: "auto" }}
                />
              </div> */}
              {newExcelData?.values.length > 0 && (
                <div className="d-flex">
                  <div
                    {...getRootProps()}
                    className="me-1"
                    id="upload-file-dropzone"
                  >
                    <input {...getInputProps()} hidden />
                    <Button
                      variant="primary"
                      className=""
                      size="sm"
                      onClick={updateUsersViaExcel}
                    >
                      Import
                    </Button>
                  </div>
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => {
                      exportExcelFile(
                        newExcelData.headers,
                        newExcelData.values
                      );
                    }}
                  >
                    Export
                  </Button>
                </div>
              )}
            </div>
            {qrCode?.string ? (
              <div className="qr-code-wrapper mx-auto d-flex mb-2">
                {qrCode ? <QRCode size={240} value={qrCode.string} /> : ""}
              </div>
            ) : (
              ""
            )}
            {qrCode.isLoading ? (
              <div class="qr-code-skeleton mt-2 mb-2">
                <div class="qr-code-container">
                  <div class="qr-code-background"></div>
                  <div class="qr-code-shimmer"></div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="invitation-status-wrapper custom-scrollbar">
              <GuestData currentRow={currentRow} invitationWithUsers={invitationWithUsers} />
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Status;
