import React from "react";
import "./heroSection.scss";
import UploadFile from "../uploadFile";
import { Button, Container } from "react-bootstrap";
import { curved_design, right_arrow } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import hero_image from "../../assets/images/heroBg.svg";

const HeroSection = () => {
  const navigate = useNavigate();
  return (
    // <div className="position-relative">
    //   <div className="curved_design_wrapper position-absolute">
    //     {curved_design()}
    //   </div>
    //   <Container className="hero-section ">
    //     <div className="d-flex flex-wrap position-relative">
    //       <div className=" custom-input-wrapper-parent mx-auto">
    //         <UploadFile />
    //       </div>
    //       <div className=" d-flex align-items-center primary-heading-wrapper px-0">
    //         <div className="">
    //           <p className="fs-50 primary-heading fw-800 mb-0">
    //             Fastest way <br /> for sending dynamic
    //             <span className="text-primary"> messages</span> to whatsapp
    //           </p>
    //           <div>
    //             <Button
    //               variant="primary"
    //               className="d-flex align-items-center justify-content-center free-trial-button p-0"
    //               onClick={() => {
    //                 navigate("/send-invitation");
    //               }}
    //             >
    //               <span className="pe-2 fs-25">Free Trial</span>{" "}
    //               {right_arrow("36px", "36px")}
    //             </Button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </Container>
    // </div>

    <Container>
      <div className="hero-section">
        <div className="hero-section-content">
          <div className="hero-section-content-left">
            <h1 className="hero-section-heading">
              Create. Collaborate. Customize.
            </h1>

            <p className="hero-section-description">
              Create, collaborate, and customize your WhatsApp messages with
              ease. Our platform empowers you to craft personalized messages,
              work together with your team, and tailor communications to your
              specific needs. Experience the fastest way to send dynamic
              WhatsApp messages at scale.
            </p>

            <div className="hero-section-content-left-bottom">
              <div className="d-flex align-items-center gap-2">
                <div className="hero-section-content-left-bottom-icon">
                  {/* SVG as JSX */}
                  <svg
                    preserveAspectRatio="xMidYMid meet"
                    data-bbox="23.5 23.5 153 153"
                    viewBox="23.5 23.5 153 153"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                    data-type="color"
                    role="presentation"
                    aria-hidden="true"
                    aria-label=""
                  >
                    <defs>
                      <style>
                        {`
                        #comp-kycyn6r2 svg [data-color="1"] {fill: #006AA8;}
                        #comp-kycyn6r2 svg [data-color="2"] {fill: #FFFFFF;}
                      `}
                      </style>
                    </defs>
                    <g>
                      <path
                        d="M158.026 23.5H41.974C31.771 23.5 23.5 31.771 23.5 41.974v116.052c0 10.203 8.271 18.474 18.474 18.474h116.052c10.203 0 18.474-8.271 18.474-18.474V41.974c0-10.203-8.271-18.474-18.474-18.474z"
                        fill="#111010"
                        data-color="1"
                      />
                      <path
                        d="M133.217 75.772h-6.588c0-14.683-11.945-26.629-26.629-26.629S73.37 61.089 73.37 75.772h-6.588c0-18.316 14.901-33.217 33.217-33.217 18.317 0 33.218 14.901 33.218 33.217z"
                        fill="#FFFFFF"
                        data-color="2"
                      />
                      <path
                        d="M130.662 82.431H69.338c-5.391 0-9.762 3.947-9.762 8.816v55.381c0 4.869 4.371 8.816 9.762 8.816h61.324c5.391 0 9.762-3.947 9.762-8.816V91.247c0-4.868-4.371-8.816-9.762-8.816zm-11.973 35.105-22.531 22.335a.907.907 0 0 1-.638.262h-7.143c-.806 0-1.21-.975-.64-1.545l14.823-14.824c.57-.57.167-1.545-.64-1.545H81.934c-.807 0-1.21-.975-.64-1.545l22.812-22.813c-.149-.149 4.855-.124 7.834-.095.802.008 1.196.978.629 1.545l-15.131 15.131c-.57.57-.167 1.545.64 1.545h19.973c.81 0 1.212.979.638 1.549z"
                        fill="#FFFFFF"
                        data-color="2"
                      />
                    </g>
                  </svg>
                </div>
                <p className="mb-0">Secure & reliable</p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="hero-section-content-left-bottom-icon">
                  {/* SVG as JSX */}
                  <svg
                    preserveAspectRatio="xMidYMid meet"
                    data-bbox="23.5 23.5 153 153"
                    viewBox="23.5 23.5 153 153"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                    data-type="color"
                    role="presentation"
                    aria-hidden="true"
                    aria-label=""
                  >
                    <defs>
                      <style>
                        {`
                        #comp-kycyn6r2 svg [data-color="1"] {fill: #006AA8;}
                        #comp-kycyn6r2 svg [data-color="2"] {fill: #FFFFFF;}
                      `}
                      </style>
                    </defs>
                    <g>
                      <path
                        d="M158.026 23.5H41.974C31.771 23.5 23.5 31.771 23.5 41.974v116.052c0 10.203 8.271 18.474 18.474 18.474h116.052c10.203 0 18.474-8.271 18.474-18.474V41.974c0-10.203-8.271-18.474-18.474-18.474z"
                        fill="#111010"
                        data-color="1"
                      />
                      <path
                        d="M133.217 75.772h-6.588c0-14.683-11.945-26.629-26.629-26.629S73.37 61.089 73.37 75.772h-6.588c0-18.316 14.901-33.217 33.217-33.217 18.317 0 33.218 14.901 33.218 33.217z"
                        fill="#FFFFFF"
                        data-color="2"
                      />
                      <path
                        d="M130.662 82.431H69.338c-5.391 0-9.762 3.947-9.762 8.816v55.381c0 4.869 4.371 8.816 9.762 8.816h61.324c5.391 0 9.762-3.947 9.762-8.816V91.247c0-4.868-4.371-8.816-9.762-8.816zm-11.973 35.105-22.531 22.335a.907.907 0 0 1-.638.262h-7.143c-.806 0-1.21-.975-.64-1.545l14.823-14.824c.57-.57.167-1.545-.64-1.545H81.934c-.807 0-1.21-.975-.64-1.545l22.812-22.813c-.149-.149 4.855-.124 7.834-.095.802.008 1.196.978.629 1.545l-15.131 15.131c-.57.57-.167 1.545.64 1.545h19.973c.81 0 1.212.979.638 1.549z"
                        fill="#FFFFFF"
                        data-color="2"
                      />
                    </g>
                  </svg>
                </div>
                <p className="mb-0">Flexibility & Scalability</p>
              </div>
              <div className="d-flex align-items-center gap-2">
                <div className="hero-section-content-left-bottom-icon">
                  {/* SVG as JSX */}
                  <svg
                    preserveAspectRatio="xMidYMid meet"
                    data-bbox="23.5 23.5 153 153"
                    viewBox="23.5 23.5 153 153"
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                    data-type="color"
                    role="presentation"
                    aria-hidden="true"
                    aria-label=""
                  >
                    <defs>
                      <style>
                        {`
                        #comp-kycyn6r2 svg [data-color="1"] {fill: #006AA8;}
                        #comp-kycyn6r2 svg [data-color="2"] {fill: #FFFFFF;}
                      `}
                      </style>
                    </defs>
                    <g>
                      <path
                        d="M158.026 23.5H41.974C31.771 23.5 23.5 31.771 23.5 41.974v116.052c0 10.203 8.271 18.474 18.474 18.474h116.052c10.203 0 18.474-8.271 18.474-18.474V41.974c0-10.203-8.271-18.474-18.474-18.474z"
                        fill="#111010"
                        data-color="1"
                      />
                      <path
                        d="M133.217 75.772h-6.588c0-14.683-11.945-26.629-26.629-26.629S73.37 61.089 73.37 75.772h-6.588c0-18.316 14.901-33.217 33.217-33.217 18.317 0 33.218 14.901 33.218 33.217z"
                        fill="#FFFFFF"
                        data-color="2"
                      />
                      <path
                        d="M130.662 82.431H69.338c-5.391 0-9.762 3.947-9.762 8.816v55.381c0 4.869 4.371 8.816 9.762 8.816h61.324c5.391 0 9.762-3.947 9.762-8.816V91.247c0-4.868-4.371-8.816-9.762-8.816zm-11.973 35.105-22.531 22.335a.907.907 0 0 1-.638.262h-7.143c-.806 0-1.21-.975-.64-1.545l14.823-14.824c.57-.57.167-1.545-.64-1.545H81.934c-.807 0-1.21-.975-.64-1.545l22.812-22.813c-.149-.149 4.855-.124 7.834-.095.802.008 1.196.978.629 1.545l-15.131 15.131c-.57.57-.167 1.545.64 1.545h19.973c.81 0 1.212.979.638 1.549z"
                        fill="#FFFFFF"
                        data-color="2"
                      />
                    </g>
                  </svg>
                </div>
                <p className="mb-0">Collaborate Better</p>
              </div>
            </div>

            <Button
              variant="primary"
              className="fs-18 px-4 py-1 login-button mt-5"
              onClick={() => {
                navigate("/send-invitation");
              }}
            >
              Get Started
            </Button>
          </div>
          <div className="hero-section-image">
            <img src={hero_image} alt="hero" />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HeroSection;
