import React, { useRef, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
import { PhoneInput } from "../../phoneInput";

export const CustomerModel = (props) => {
  const [contact, setContact] = useState({
    invitationName: "",
    name: "",
    email: "",
    phone: "",
  });

  const [validated, setValidated] = useState(false);
  const [phoneStatus, setPhoneStatus] = useState(false);

  const handleStoreData = () => {
    props.storeAllData(contact);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity()) {
      handleStoreData();
      props.onHide();
    }
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const handlePhone = (phone) => {
    setContact({ ...contact, phone: phone });
  };

  const handlePhoneStatus = (status) => {
    setPhoneStatus(status);
  };
  const submitRef = useRef();

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Contact</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="validationCustom01">
              <Form.Label>Invitation Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Wedding invitation"
                value={contact.invitationName}
                onChange={(e) => {
                  setContact({ ...contact, invitationName: e.target.value });
                }}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide name for your invitation.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="pt-2">
              <Form.Label>Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Name"
                value={contact.name}
                onChange={(e) => {
                  setContact({ ...contact, name: e.target.value });
                }}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide your name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="pt-2">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="example@gmail.com"
                value={contact.email}
                onChange={(e) => {
                  setContact({ ...contact, email: e.target.value });
                }}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please provide your email.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustomUsername" className="pt-2">
              <Form.Label>Phone</Form.Label>
              <PhoneInput
                handlePhone={handlePhone}
                handlePhoneStatus={handlePhoneStatus}
              />
            </Form.Group>
            <Button className="d-none" type="submit" ref={submitRef}>
              Submit form
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
          <Button
            type="submit"
            // disabled={!validated}
            onClick={() => {
              submitRef.current.click();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
