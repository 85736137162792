import React from "react";
import "./testimonials.scss";
import UploadFile from "../uploadFile";
import { Button, Container } from "react-bootstrap";
import { curved_design, right_arrow } from "../../assets/icons";
import { useNavigate } from "react-router-dom";

const TestimonialSection = () => {
  const navigate = useNavigate();
  return (
    <section className="testimonial-section-container">
      <Container>
        <div className="testimonial-section">
          <div className="">
            <h1>What Our Customers Say</h1>
            <p className="mt-4 testimonial-section-header-text">
              Our platform is designed to make sending and managing digital
              invitations easy and stress-free. Here are some of the features we
              offer:
            </p>
          </div>
          <div className="testimonial-section-content mt-5">
            <div className="testimonial-section-content-item">
              <div className="testimonial-section-content-item-text">
                <p>
                  "I recently used this platform to send invitations to my
                  daughter's birthday party. The process was incredibly easy,
                  and the invitations looked great. The RSVP tracking feature
                  was also very helpful in keeping track of who was attending."
                </p>
                <div className="testimonial-section-content-item-text-name">
                  <h5>John Doe</h5>
                  <p>New York, NY</p>
                </div>
              </div>
            </div>
            <div className="testimonial-section-content-item">
              <div className="testimonial-section-content-item-text">
                <p>
                  "I recently used this platform to send invitations to my
                  daughter's birthday party. The process was incredibly easy,
                  and the invitations looked great. The RSVP tracking feature
                  was also very helpful in keeping track of who was attending."
                </p>
                <div className="testimonial-section-content-item-text-name">
                  <h5>John Doe</h5>
                  <p>New York, NY</p>
                </div>
              </div>
            </div>
            <div className="testimonial-section-content-item">
              <div className="testimonial-section-content-item-text">
                <p>
                  "I recently used this platform to send invitations to my
                  daughter's birthday party. The process was incredibly easy,
                  and the invitations looked great. The RSVP tracking feature
                  was also very helpful in keeping track of who was attending."
                </p>
                <div className="testimonial-section-content-item-text-name">
                  <h5>John Doe</h5>
                  <p>New York, NY</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TestimonialSection;
