import { Button, Offcanvas, Stack } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import brand_logo from "../../assets/logo/rapid-sender-logo.png";
import "./index.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { profile_icon, right_arrow } from "../../assets/icons";

const NavbarComponent = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const currentLocation = location.pathname;
  const invitationId = params?.invitationId;

  return (
    // <Navbar bg="white" expand="md" className="p-0" sticky="top">
    //   <Container fluid>
    //     <Navbar.Brand
    //       className="rapid-sender-logo"
    //       onClick={() => navigate("/")}>
    //       <img
    //         alt=""
    //         src={brand_logo}
    //         width="60"
    //         height="60"
    //         className="d-inline-block align-top"
    //       />
    //     </Navbar.Brand>
    //     <b className="brand-text fw-700 text-nowrap">RAPID SENDER</b>
    //     <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
    //     <Navbar.Offcanvas
    //       id={`offcanvasNavbar-expand-md`}
    //       className="flex-grow-0"
    //       aria-labelledby={`offcanvasNavbarLabel-expand-md`}
    //       placement="end">
    //       <Offcanvas.Header closeButton>
    //         <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
    //           Offcanvas
    //         </Offcanvas.Title>
    //       </Offcanvas.Header>
    //       <Offcanvas.Body>
    //         {currentLocation === "/" && (
    //           <Nav className="justify-content-end mr-182">
    //             <Nav.Link href="/" className="fs-22 p-0 mr-37">
    //               App
    //             </Nav.Link>
    //             <Nav.Link href="/privacy" className="fs-22 p-0 mr-37">
    //               Privacy
    //             </Nav.Link>
    //             <Nav.Link href="/terms" className="fs-22 p-0">
    //               Terms
    //             </Nav.Link>
    //           </Nav>
    //         )}
    //         {currentLocation === "/sign-in" ||
    //           (currentLocation === "/sign-up" && (
    //             <Nav className="justify-content-end mr-213 align-items-center">
    //               <Nav.Link className="fs-22 p-0 mr-37">Login</Nav.Link>
    //               <Button variant="primary" className="fs-18">
    //                 Sign up Free {right_arrow("24px", "24px")}
    //               </Button>
    //             </Nav>
    //           ))}
    //         {(currentLocation === "/send-invitation" ||
    //           currentLocation === "/variable" ||
    //           currentLocation === "/invitation-status" ||
    //           currentLocation === `/invitation-status/${invitationId}` ||
    //           currentLocation === "/invitation-message") && (
    //           <Nav className="justify-content-end mr-213 align-items-center">
    //             <Nav.Link className="p-0 mr-37">{profile_icon()}</Nav.Link>
    //           </Nav>
    //         )}
    //       </Offcanvas.Body>
    //     </Navbar.Offcanvas>
    //   </Container>
    // </Navbar>

    <Navbar bg="white" expand="md" className="p-0 navbar-main" sticky="top">
      <Container>
        <div className="navbar-container landing-page-navbar-container">
          <Navbar.Brand
            className="rapid-sender-logo cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              alt=""
              src={brand_logo}
              width="60"
              height="60"
              className="d-inline-block align-top"
            />
            <div>
              <b className="brand-text fw-600 text-nowrap">RAPID SENDER</b>
              <p className="brand-text-description mb-0">
                Create. Collaborate. Customize.
              </p>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-md`}
            className="flex-grow-0"
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                Rapid Sender
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              {currentLocation === "/" && (
                <div className="d-flex justify-content-between offcanvas-navbar">
                  <Nav className="justify-content-end mr-182 offcanvas-navbar-nav">
                    <Nav.Link href="/" className="fs-18 p-0 mr-37">
                      App
                    </Nav.Link>
                    <Nav.Link href="/privacy" className="fs-18 p-0 mr-37">
                      Privacy
                    </Nav.Link>
                    <Nav.Link href="/terms" className="fs-18 p-0">
                      Terms
                    </Nav.Link>
                  </Nav>
                  <div className="offcanvas-login-button">
                    <Button
                      variant="primary"
                      className="fs-18 px-4 py-1 login-button"
                      onClick={() => {
                        navigate("/send-invitation");
                      }}
                    >
                      Get Started
                    </Button>
                  </div>
                </div>
              )}
              {currentLocation === "/sign-in" ||
                (currentLocation === "/sign-up" && (
                  <Nav className="justify-content-end mr-213 align-items-center">
                    <Nav.Link className="fs-22 p-0 mr-37">Login</Nav.Link>
                    <Button variant="primary" className="fs-18">
                      Sign up Free {right_arrow("24px", "24px")}
                    </Button>
                  </Nav>
                ))}
              {(currentLocation === "/send-invitation" ||
                currentLocation === "/variable" ||
                currentLocation === "/invitation-status" ||
                currentLocation === `/invitation-status/${invitationId}` ||
                currentLocation === "/invitation-message") && (
                <Nav className="justify-content-end mr-213 align-items-center">
                  <Nav.Link
                    className="p-0 mr-37"
                    onClick={() => {
                      localStorage.clear();
                      window.location.reload();
                      // navigate("/send-invitation");
                    }}
                  >
                    {/* {profile_icon()} */}
                    🔁Restart the process
                  </Nav.Link>
                </Nav>
              )}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
