import React from "react";
import "./index.scss";
import { useState } from "react";
import { useEffect } from "react";
import { validateString } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { setExcelData } from "../../redux-slice/filesSlice";
import { removeObjectValues } from "../../utils/object";

const EditableTable = () => {
  const { excelData } = useSelector((state) => state.files);

  console.log("excelData", excelData);

  const dispatch = useDispatch();

  const handleUpdate = (key, value, i) => {
    console.log("handleUpdate--called");
    const clonedExcelData = JSON.parse(JSON.stringify(excelData));
    clonedExcelData.guestData[i][key] = value;
    dispatch(setExcelData(clonedExcelData));
  };

  const checkForLastRow = () => {
    const isFilled = Object.values(
      excelData.guestData[excelData.guestData.length - 1]
    ).some((col) => validateString(col));
    return isFilled;
  };

  const addRow = () => {
    if (checkForLastRow()) {
      const clonedExcelData = JSON.parse(JSON.stringify(excelData));
      const clonedRow = removeObjectValues({ ...clonedExcelData.guestData[0] });
      clonedExcelData?.guestData.push(clonedRow);
      dispatch(setExcelData(clonedExcelData));
    }
  };

  useEffect(() => {
    if (excelData.guestData?.length > 0) {
      addRow();
    }
  }, [excelData?.guestData]);

  return (
    <table className="editable-table" bordered hover>
      <thead>
        <tr>
          <th>#</th>
          {excelData?.guestData?.length > 0 &&
            Object.keys(excelData.guestData[0]).map((header, i) => {
              return (
                header !== "_id" &&
                header !== "isUpdating" && (
                  <th key={`editable-table-header.${i}`}>{header}</th>
                )
              );
            })}
        </tr>
      </thead>
      <tbody>
        {excelData?.guestData?.length > 0 &&
          excelData.guestData.map((guest, index) => {
            const keys = Object.keys(guest);
            return (
              <tr key={`guest_${index}`}>
                <td>{index + 1}</td>
                {Object.values(guest).map((value, i) => {
                  return (
                    Object.keys(guest)[i] !== "_id" &&
                    Object.keys(guest)[i] !== "isUpdating" && (
                      <td
                        key={`guestInfo.${index}.${i}`}
                        contentEditable
                        onBlur={(e) => {
                          validateString(e.target.innerText) &&
                            handleUpdate(keys[i], e.target.innerText, index);
                        }}
                      >
                        {value}
                      </td>
                    )
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default EditableTable;
