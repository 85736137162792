import React from "react";
import "./index.scss";
import { Button, Container } from "react-bootstrap";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="content">
          <div className="services">
            <h4>Resources</h4>
            <p>
              <a href="#">Getting Started</a>
            </p>
          </div>
          <div className="social-media">
            <h4>Community</h4>
            <p>
              <a href="#">
                <i className="fab fa-linkedin"></i> Blog
              </a>
            </p>
            <p>
              <a href="#">
                <i className="fab fa-twitter"></i> Twitter
              </a>
            </p>
            <p>
              <a href="#">
                <i className="fab fa-github"></i> LinkedIn
              </a>
            </p>
            <p>
              <a href="#">
                <i className="fab fa-facebook"></i> Facebook
              </a>
            </p>
            <p>
              <a href="#">
                <i className="fab fa-instagram"></i> Instagram
              </a>
            </p>
          </div>
          <div className="links">
            <h4>About us</h4>
            <p>
              <a href="#">Home</a>
            </p>
            <p>
              <a href="#">About us</a>
            </p>
            <p>
              <a href="#">Privacy & Security</a>
            </p>
          </div>
          <div className="details">
            <h4 className="address">Address</h4>
            <p>
              Hall 302, Dhara Trade Centre, <br /> Mota Varachha, Surat, Gujarat
              394101
            </p>
            <h4 className="mobile">Mobile</h4>
            <p>
              <a href="#">+91 6352 746259</a>
            </p>
            <h4 className="mail">Email</h4>
            <p>
              <a href="#">hello@dobedosoft.com</a>
            </p>
          </div>
        </div>
        <footer>
          <hr />© 2024 Rapid Sender
        </footer>
      </div>
    </>
  );
};

export default Footer;
