import React from "react";
import NavbarComponent from "../components/navbar";
import HeroSection from "../components/heroSection";
import Tutorial from "../components/tutorialSection";
import Testimonial from "../components/testimonialSection";
import CallToAction from "../components/callToAction";
import Footer from "../components/footer";
import OccasionSection from "../components/occasion";
import OffersSection from "../components/offers";
import TestimonialSection from "../components/testimonials";
import InvitationBioSection from "../components/invitationBio";

const HomePage = () => {
  return (
    <div className="">
      <NavbarComponent />
      <HeroSection />
      <OccasionSection />
      <OffersSection />
      <TestimonialSection />
      <InvitationBioSection />
      {/* <Tutorial /> */}
      {/* <Testimonial /> */}
      {/* <CallToAction /> */}
      <Footer />
    </div>
  );
};

export default HomePage;
